import { FormattedMessage } from 'react-intl';
import { Navbar, NavbarBrand } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { AccountCenter } from './AccountCenter';
import { getConfig } from '@/helpers/config';
import { UserSettings } from '@/components/layout/components/UserSettings';

export function Header() {
  const { fx_url } = getConfig();

  return (
    <nav className="navbar navbar-expand d-flex justify-content-between align-content-center border-bottom">
      <NavbarBrand href="/">
        <NavLink to="/" className="navbar-title-link">
          <img src="/favicon.svg" alt="SG" className="border border-white" />
          <div className="navbar-title-divider"></div>

          <div className="navbar-service-name">FX TCA</div>
        </NavLink>
      </NavbarBrand>
      <Navbar.Toggle aria-controls="responsive-navbar" className="me-auto">
        <FormattedMessage id="menu.title" />
        <i className="icon icon-md text-secondary">arrow_drop_down</i>
      </Navbar.Toggle>

      <Navbar.Collapse id="responsive-navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <NavLink to="/" exact className="nav-link">
              <FormattedMessage id="menu.algoOrders" />
            </NavLink>
          </li>
          <li className="nav-item">
            <a href={fx_url} className="nav-link" target="_blank" rel="noopener">
              <FormattedMessage id="menu.fx" />
            </a>
          </li>
        </ul>
      </Navbar.Collapse>

      <UserSettings />
      <AccountCenter />
    </nav>
  );
}
