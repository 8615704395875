import { getConfig } from '@/helpers/config';
import { SgwtHelpCenter } from '@sgwt/sgwt-widgets-react';

export function HelpCenter(): JSX.Element {

  return (
    <aside>
      <SgwtHelpCenter
        applicationId={import.meta.env.VITE_ID as string}
        mailSubject={`[SGME-FXTCA:${getConfig().app_env.toUpperCase()}] Help center message`}
      />
    </aside>
  );
}
